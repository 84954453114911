<template>
    <section class="references-section">
        <amp-row-first>
            <template #input>
                <v-btn
                    text
                    class="justify-start pa-8"
                    block
                    :color="open ? 'primary' : 'secondary'"
                    :disabled="disabled"
                    @click="toggleOpen"
                >
                    <v-icon small left>
                        {{ open ? 'chevron-down' : 'chevron-up' }}
                    </v-icon>
                    {{ label }}
                </v-btn>
            </template>
        </amp-row-first>

        <v-expand-transition>
            <section v-show="open" class="mt-1">
                <slot></slot>
            </section>
        </v-expand-transition>

        <amp-row-last v-if="open" />
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { AmpRowFirst, AmpRowLast } from '@/components/AmpModule/AmpPage';
import { Watch } from '@/utils/decorators';

const AmpExpansionPanelProps = Vue.extend({
    props: {
        expand: {
            type: Boolean,
            default() {
                return true;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        label: {
            type: String,
            default() {
                return 'Click to Expand';
            }
        }
    }
});

@Component({
    components: {
        AmpRowFirst,
        AmpRowLast
    }
})
export default class AmpExpansionPanel extends AmpExpansionPanelProps {
    open = this.expand;

    @Watch('expand')
    onExpandChange(expand: boolean) {
        this.open = expand;
    }

    toggleOpen() {
        this.open = !this.open;
    }
}
</script>

<style lang="scss" scoped>
.references-section::v-deep {
    margin-bottom: 1em;

    .elevated-input-first {
        padding: 0 !important;
        border-top: 6px solid $faded-blue !important;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
</style>
